<template lang="pug">
div(v-if='Servicios')
	//-Pc
	.d-none.d-md-block
		v-container(fluid v-scrollanimation).pa-0
			v-img(height='100%' :src='Servicios.imagen' contain)
				.fila-texto
					h3( style='line-height : 50px' v-html='Servicios.texto').white--text
					img(:src='Inicio.cuadro_recortado')
		.bloque-servicios
			v-row.justify-center.align-center.centrado
				v-col(:cols='titulo.substring(0, 8)==="<p>Modal" ? 6 : 4' 
				
				v-for='{tieneBoton, texto, imagen, titulo}, indice in Servicios.servicios' 
				:key='indice'
				v-scrollanimation card='true' 
				v-if='titulo.substring(0, 8)==="<p>Modal" ? mostrarModalidades : true ')
					v-card(
						:cardTexto='cantidadDeTexto(texto)> 300 ? true : false'
					).mx-auto.card.justify-center.align-items-center.gris.elevation-0
						img(:src='imagen')
						v-row.texto.justify-center.align-items-start
							p( style='line-height : 5px; color:#011f43' v-html='titulo.substring(0, 8)==="<p>Modal" ? `<p>${icon}${titulo.substring(3)}` : titulo ').body-1.font-weight-black
							p( v-html='texto').body-2.font-weight-light.text-center 
							.bloque-naranja.naranja.align-items-center.justify-center(v-if='tieneBoton' @click='mostrarModalidades = !mostrarModalidades')
								span 2 modalidades.
								v-icon(color='white' right='' medium) {{mostrarModalidades ? "fa fa-angle-up" : "fa fa-angle-down"}} 
								
	//- cuidado hombre trabajando xd
	.d-md-none
		v-container(fluid).pa-0
			v-img(height='200px'  :src='Servicios.imagen' ).d-none.d-sm-none
			v-img(height='auto'  :src='Servicios.imagen' ).d-flex.d-sm-block

				.fila-texto-movil
					p(v-html='Servicios.textomovil' style='line-height : 4px').font-weight-regular.white--text.d-sm-none
					p(v-html='Servicios.textomovil' style='line-height : 1rem; font-size: 30px;').font-weight-regular.white--text.d-none.d-sm-block.d-md-none
					img(:src='Inicio.cuadro_recortado').d-sm-none.d-md-flex
			v-row.justify-center.align-center(style='padding-bottom: 10%; padding-top: 10%')
				v-col(cols='10' sm='5'  v-for='{texto, imagen, titulo, tieneBoton}, i in Servicios.servicios' :key='i'
				v-scrollanimation card='true'
				v-if='titulo.substring(0, 8)==="<p>Modal" ? mostrarModalidades : true ')
					v-card( style='border-radius: 30px; padding-top: 2%'
					:cardTexto='cantidadDeTexto(texto)> 300 ? true : false').gris.cardMovil.mt-3
						v-img(:src='imagen' height='120px' width='100%' contain)
						
						v-card-text(style='padding: 5%')
							p( style='line-height : 5px; color:#011f43;padding:1em' v-html='titulo.substring(0, 8)==="<p>Modal" ? `<p>${icon}${titulo.substring(3)}` : titulo').body-1.font-weight-black.text-center
							p( v-html='texto').body-2.font-weight-light.text-center
						v-row.justify-center
							v-btn(rounded=''  color='naranja' dark='' small=''
							style='position: absolute; top: 98%'
							v-if='tieneBoton' @click='mostrarModalidades = !mostrarModalidades').justify-center.align-center.elevation-0 
								| 2 modalidades
								v-icon(color='white' right='') {{mostrarModalidades ? "fa fa-angle-down" : "fa fa-angle-up"}}


		
</template>
<script>
  export default {
    data: () => ({
      mostrarModalidades: false,
			icon: '<i class="fas fa-chevron-right" style="color:#e74212;!important; margin-right: 7px"></i>'
    }),
	methods: {
		cantidadDeTexto(texto){
			return texto.length
		}
	},
}
</script>
<style lang="sass" scoped>
.cardMovil
	height: 395px
	&[cardTexto='true']
		height: 520px
.fila-texto-movil
	position: absolute
	display: flex
	top: 3em
	left: 2em
	font-size: 14px
	img
		position: absolute
		display: flex
		width: 30px
		height: 30px
		top: 4rem
		left: -1em
.medidamovil
	padding-bottom: 10%

.fila-texto
	position: absolute
	display: flex
	top: 20%
	left: 10%
	img
		position: absolute
		display: flex
		width: 80px
		height: 70px
		top: 90%
		left: -2em
.bloque-servicios
	display: flex
	flex-direction: row
	width: 100%
	height: auto
	padding-top: 3em
	margin-bottom: 50px
	.centrado
		padding-right: 0.01em
		
	
		.card
			width: 280px
			height: 360px
			border-radius: 25px
			margin-top: 6em
			&[cardTexto='true']
				height: 500px
			.texto
				display: flex
				justify-content: center
				text-align: center!important
				position: absolute
				width: 200px
				top: 8em
				left: 3em
			img
				position: absolute
				top: -17%
				height: 150px
				left: 2em
		.bloque-naranja
			cursor: pointer
			width: 170px
			height: 30px
			position: absolute
			top: 90%
			border-radius: 13px
			text-align: center
			padding-top: 2px
			span
				color: white

	.before-enter
		opacity: 0
		transform: translatex(-100%)
		transition: all 1.5s ease-out 
		&[card='true']
			transform: scale(.5) rotateZ(-25deg)
			transition: all 1s ease-out  
	.enter
		opacity: 1
		transform: translateX(0px)
		&[card='true']
			transform: scale(1) rotateZ(0deg)

</style>